// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TQwe3NWCw: {hover: true}};

const cycleOrder = ["TQwe3NWCw"];

const serializationHash = "framer-mLeZN"

const variantClassNames = {TQwe3NWCw: "framer-v-1t4itcl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, label, width, ...props}) => { return {...props, KacjG7Aqe: label ?? props.KacjG7Aqe ?? "Login"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;label?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KacjG7Aqe, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "TQwe3NWCw", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.button {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1t4itcl", className, classNames)} data-framer-name={"Variant 1"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"TQwe3NWCw"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, opacity: 1, ...style}} variants={{"TQwe3NWCw-hover": {opacity: 0.4}}} {...addPropertyOverrides({"TQwe3NWCw-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"left"} className={"framer-awgxkp"} data-framer-name={"Button Label"} fonts={["CUSTOM;Satoshi Black"]} layoutDependency={layoutDependency} layoutId={"CeqVtvK4s"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Login</span><br></span></span>"} style={{"--framer-font-family": "\"Satoshi Black\", \"Satoshi Black Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0.72px", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "rgb(0, 2, 24)", "--framer-text-decoration": "none", "--framer-text-transform": "uppercase"}} text={KacjG7Aqe} verticalAlignment={"top"} withExternalLayout/></motion.button></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mLeZN.framer-jg9zsi, .framer-mLeZN .framer-jg9zsi { display: block; }", ".framer-mLeZN.framer-1t4itcl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-mLeZN .framer-awgxkp { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mLeZN.framer-1t4itcl { gap: 0px; } .framer-mLeZN.framer-1t4itcl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mLeZN.framer-1t4itcl > :first-child { margin-left: 0px; } .framer-mLeZN.framer-1t4itcl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 42.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"jfKDQhJny":{"layout":["auto","auto"]}}}
 * @framerVariables {"KacjG7Aqe":"label"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerxorcBRGfz: React.ComponentType<Props> = withCSS(Component, css, "framer-mLeZN") as typeof Component;
export default FramerxorcBRGfz;

FramerxorcBRGfz.displayName = "Button/Link";

FramerxorcBRGfz.defaultProps = {height: 24, width: 42.5};

addPropertyControls(FramerxorcBRGfz, {KacjG7Aqe: {defaultValue: "Login", displayTextArea: false, title: "Label", type: ControlType.String}} as any)

addFonts(FramerxorcBRGfz, [{explicitInter: true, fonts: [{family: "Satoshi Black", source: "custom", url: "https://framerusercontent.com/assets/2Suq1Nq5ylW8cvGooKbecoKecJ8.otf"}]}], {supportsExplicitInterCodegen: true})